import { Button, Form, Input, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { FC, useEffect } from "react";
import { validateMessages } from "../../common/constants/validateMessages";
import { getSelectOptionFromDictionary } from "../../common/helpers";
import { getGamesDictionary, selectGamesDictionary } from "../../redux/features/dictionariesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ICollectionDTO, ICollection } from "../../types/ICollection";

interface CollectionFormProps {
    collection?: ICollection | null;
    isLoading: boolean;
    onSubmitValues: ((data: ICollectionDTO) => void);
}

const CollectionForm: FC<CollectionFormProps> = ({ isLoading, collection, onSubmitValues }) => {
    const [form] = useForm<ICollectionDTO>();

    const games = useAppSelector(selectGamesDictionary);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getGamesDictionary());
    }, [dispatch]);

    useEffect(() => {
        if (collection) {
            form.setFieldsValue(collection);
        }
    }, [collection, form]);

    const onFinish = (values: ICollectionDTO) => {
        onSubmitValues(values);
    }

    return (
        <Spin spinning={isLoading}>
            <Form<ICollectionDTO>
                form={form}
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Contract"
                    name="contractAddress"
                    rules={[{ required: false }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Paper Contract ID"
                    name="paperContractId"
                    rules={[{ required: false }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Game"
                    name="game"
                    rules={[{ required: true }]}
                >
                    <Select options={games.map(getSelectOptionFromDictionary)} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}

export default CollectionForm;
