import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AuthService, { LoginPayload, LoginResponse } from '../../services/auth.service';
import { IAuthUser } from '../../types/IUser';
import { RootState } from '../store';

interface IUserState {
  user: IAuthUser | null;
  token: string | null;
  refreshToken: string | null;
  changePasswordData?: {
    isTemporaryPass: true;
    email: string;
  };
}

const initialState: IUserState = {
  user: null,
  token: null,
  refreshToken: null,
};

export const login = createAsyncThunk<LoginResponse, LoginPayload | {accessToken: string}>(
  'auth/login',
  async (data, _thunkAPI) => {
    const response = await AuthService.login(data);
    return response;
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, thunkAPI) => {
    await AuthService.logout();
    thunkAPI.dispatch(clearAuthData());
  }
)

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    clearAuthData: () => initialState,
    setAuthData: (_state, action: PayloadAction<{ user: IAuthUser, token: string, refreshToken: string }>) => action.payload,
    setChangePasswordData: (state, action: PayloadAction<string>) => ({...state, changePasswordData: { isTemporaryPass: true, email: action.payload }})
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      return { ...state, user: action.payload.user, token: action.payload.accessToken, refreshToken: action.payload.refreshToken };
    })
  }
});

export default authSlice.reducer;

export const {
  clearAuthData,
  setAuthData,
  setChangePasswordData,
} = authSlice.actions;

export const authSliceName = authSlice.name;

export const getUser = (state: RootState) => state.auth.user;
export const getChangePasswordData = (state: RootState) => state.auth.changePasswordData;
