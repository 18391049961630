import { notification } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventForm from '../../components/forms/EventForm';
import FormPageLayout from '../../components/Layouts/FormPageLayout';
import EventsService from '../../services/event.service';
import { IEventDTO } from '../../types/IEvent';

const CreateEvent: FC = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (data: IEventDTO) => {
        try {
            setIsLoading(true);
            const event = await EventsService.createEvent(data);
            notification.success({
                message: 'Event created!',
            });
            if (event._id) {
                navigate(`/events/${event._id}`);
            }
        } catch (e) {
            setIsLoading(false);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Events">
            <EventForm isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateEvent;
