import { IGameDTO, IGame } from '../types/IGame';
import instance from './common/instance';
import { GetDataResponse, IQueryData } from './common/types';

export type GetGamesResponse = GetDataResponse<IGame>;

class GamesService {
    private static prefix = 'games';

    static getGames({ skip, limit }: IQueryData) {
        return instance.get<GetGamesResponse>(`${this.prefix}?skip=${skip}&limit=${limit}`).then((res) => res.data);
    }

    static getGame(gameId: IGame['_id']) {
        return instance.get<IGame>(`${this.prefix}/${gameId}`).then((res) => res.data);
    }

    static createGame(data: IGameDTO) {
        return instance.post<IGame>(`${this.prefix}`, data).then((res) => res.data);
    }

    static updateGame(id: IGame['_id'], data: IGameDTO) {
        return instance.put<IGame>(`${this.prefix}/${id}`, data).then((res) => res.data);
    }
}

export default GamesService;