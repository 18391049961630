import { Button, Col, Row, Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getSkipLimit } from '../../common/helpers';
import { getCollections, selectCollections } from '../../redux/features/collectionsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ICollection } from '../../types/ICollection';

const getColumns = (pathname: string): ColumnsType<ICollection> => ([
    {
        title: '#',
        dataIndex: '_id',
        key: '_id',
        render: (id) => <Link to={`${pathname}/${id}`} >{id}</Link>
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Contract',
        dataIndex: 'contractAddress',
        key: 'contractAddress',
    },
    {
        title: 'Paper Contract ID',
        dataIndex: 'paperContractId',
        key: 'paperContractId',
    },
]);

const Collections: FC = () => {
    const { data: collections, isFetching, count } = useAppSelector(selectCollections);
    const dispatch = useAppDispatch();


    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const handleTableChange: TableProps<ICollection>['onChange'] = (newPagination, _filters, _sorter) => {
        setPagination(newPagination);
    }

    useEffect(() => {
        setPagination(currentPagination => ({ ...currentPagination, total: count }))
    }, [count]);

    // NOTE: somehow eslint(react-hooks/exhaustive-deps) rule is being triggered if we use direct property access from pagination e.g: pagination.current
    const { current, pageSize } = pagination;
    useEffect(() => {
        dispatch(getCollections(getSkipLimit(pageSize, current)));
    }, [dispatch, current, pageSize]);

    const { pathname } = useLocation();

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Link to={`${pathname}/create`}>
                        <Button type="primary">Create collection</Button>
                    </Link>
                </Row>
            </Col>
            <Col span={24}>
                {/* <RequestFilters setFilters={handleFilters} /> */}
            </Col>
            <Table<ICollection>
                className="main-table"
                rowKey="_id"
                loading={isFetching}
                columns={getColumns(pathname)}
                dataSource={collections}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </Row>
    )
};

export default Collections;
