import React from 'react';
import { IRouterList } from '../../types/IRouterList';
import { RocketOutlined, ThunderboltOutlined, TrophyOutlined } from '@ant-design/icons';
import Events from '../../pages/events/Events';
import { CreateGame, Games, UpdateGame } from '../../pages/games';
import { Collections, CreateCollection, UpdateCollection } from '../../pages/collections';
import { CreateEvent, UpdateEvent } from '../../pages/events';

export const menuIcons: { [key: string]: React.ReactNode | undefined} = {
    games: <TrophyOutlined/>,
    collections: <ThunderboltOutlined />,
    events: <RocketOutlined />,
}

const RouteList: IRouterList[] = [
    {
        path: 'games',
        code: 'games',
        component: Games,
        icon: 'games',
        name: 'Games',
    },
    {
        path: 'games/:gameId',
        code: 'update_game',
        component: UpdateGame,
    },
    {
        path: 'games/create',
        code: 'create_game',
        component: CreateGame,
    },
    {
        path: 'collections',
        code: 'collections',
        component: Collections,
        icon: 'collections',
        name: 'Collections',
    },
    {
        path: 'collections/:collectionId',
        code: 'update_collection',
        component: UpdateCollection,
    },
    {
        path: 'collections/create',
        code: 'create_collection',
        component: CreateCollection,
    },
    {
        path: 'events',
        code: 'events',
        component: Events,
        icon: 'events',
        name: 'Events'
    },
    {
        path: 'events/:eventId',
        code: 'update_event',
        component: UpdateEvent,
    },
    {
        path: 'events/create',
        code: 'create_event',
        component: CreateEvent,
    },
];

export default RouteList;
