import { combineReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import authReducer, { authSliceName } from './features/authSlice';
import gamesReducer, { gamesSliceName } from './features/gamesSlice';
import collectionsReducer, { collectionsSliceName } from './features/collectionsSlice';
import dictionariesReducer, { dictionariesSliceName } from './features/dictionariesSlice';
import eventsReducer, { eventsSliceName } from './features/eventSlice';

const authPersistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    [authSliceName]: persistReducer(authPersistConfig, authReducer),
    [gamesSliceName]: gamesReducer,
    [collectionsSliceName]: collectionsReducer,
    [dictionariesSliceName]: dictionariesReducer,
    [eventsSliceName]: eventsReducer,
})

export default rootReducer;