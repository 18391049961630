import { IEventDTO, IEvent } from '../types/IEvent';
import instance from './common/instance';
import { GetDataResponse, IQueryData } from './common/types';

export type GetEventsResponse = GetDataResponse<IEvent>;

class EventsService {
    private static prefix = 'events';

    static getEvents({ skip, limit }: IQueryData) {
        return instance.get<GetEventsResponse>(`${this.prefix}?skip=${skip}&limit=${limit}`).then((res) => res.data);
    }

    static getEvent(eventId: IEvent['_id']) {
        return instance.get<IEvent>(`${this.prefix}/${eventId}`).then((res) => res.data);
    }

    static createEvent(data: IEventDTO) {
        return instance.post<IEvent>(`${this.prefix}`, data).then((res) => res.data);
    }

    static updateEvent(id: IEvent['_id'], data: IEventDTO) {
        return instance.put<IEvent>(`${this.prefix}/${id}`, data).then((res) => res.data);
    }
}

export default EventsService;