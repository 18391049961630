import { CaseReducer, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryData } from '../../services/common/types';
import GamesService, { GetGamesResponse } from '../../services/game.service';
import { IGame } from '../../types/IGame';
import { RootState } from '../store';

interface IGamesState {
    data: IGame[];
    isFetching: boolean;
    count: number;
}

const initialState: IGamesState = {
    data: [],
    count: 0,
    isFetching: false,
}

export const getGames = createAsyncThunk<GetGamesResponse, IQueryData>(
    'games/getGames',
    async (data, _thunkAPI) => {
        const response = await GamesService.getGames(data);
        return response;
    }
)

const setGamesData: CaseReducer<IGamesState, PayloadAction<GetGamesResponse>> = (state, action) => { 
    state.data = action.payload.data;
    state.count = action.payload.count, 
    state.isFetching = false 
};

export const gamesSlice = createSlice({
    initialState,
    name: 'games',
    reducers: {
        setGames: setGamesData,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGames.fulfilled, setGamesData)
            .addCase(getGames.pending, (state) => { state.isFetching = true })
            .addCase(getGames.rejected, (state) => { state.isFetching = false })
    }
});

export default gamesSlice.reducer;

export const {
    setGames,
} = gamesSlice.actions;

export const gamesSliceName = gamesSlice.name;

export const selectGames = (state: RootState) => state.games;
