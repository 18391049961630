import { IAuthUser } from '../types/IUser';
import instance from './common/instance';

export interface LoginPayload {
    email: string;
    password: string;
}

export interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    tokenType: 'bearer';
    expiresIn: number;
    createdDate: number;
    user: IAuthUser;
}

class AuthService {
    private static prefix = 'auth';

    static login(data: LoginPayload | {accessToken: string}) {
        return instance.post<LoginResponse>(`${this.prefix}/login`, data).then(res => res.data);
    }

    static logout() {
        return instance.post<LoginResponse>(`${this.prefix}/logout`).then(res => res.data);
    }

    // TODO: ADD REFRESH TOKEN FUNCTIONALITY
}

export default AuthService;
