import React, { FC } from "react";
import { Button, Card, Form, Input } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getUser, login } from "../redux/features/authSlice";
import {Navigate} from "react-router-dom";
import { validateMessages } from "../common/constants/validateMessages";
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import config from "../config";

interface LoginFormValues {
  email: string;
  password: string;
}

const app = initializeApp(config.firebase);
const auth = getAuth(app);

const LoginPage: FC = () => {
  const [form] = Form.useForm<LoginFormValues>();

  const dispatch = useAppDispatch();
  const handleFormFinish = (values: LoginFormValues) => {
    dispatch(login(values));
  }

  const handleGoogleAuth = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      const accessToken = await result.user.getIdToken();
      dispatch(login({accessToken}));
    } catch (e) {
      console.log('>>> handleGoogleAuth ERROR: ', e);
    }
  }

  const user = useAppSelector(getUser);
  if(user) {
    return <Navigate to="/games" />
  }

  return (
    <div className="main-container">
      <div className="common-flex-alc-jcs main-container">
        <Card className="card-container common-flex-column">
          <Form
            form={form}
            onFinish={handleFormFinish}
            validateMessages={validateMessages}
          >
            <div className="login-container">
              <span className="login-title">Login</span>
              <Form.Item
                name="email"
                rules={[{ required: true }]}
              >
                <Input
                  prefix={<UserOutlined className="login-icon" />}
                  placeholder="Email"
                  type="email"
                  className="login-input"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password
                  className="login-input"
                  prefix={<LockOutlined className="login-icon" />}
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ color: "#fff", marginLeft: "0.3rem" }} />
                    ) : (
                      <EyeInvisibleOutlined
                        style={{ color: "#fff", marginLeft: "0.3rem" }}
                      />
                    )
                  }
                />
              </Form.Item>
              <Button className="submit-btn" htmlType="submit">Submit</Button>
            </div>
          </Form>
          <div style={{textAlign: 'center', paddingTop: '10px'}}>
            or<br/>
            <a onClick={handleGoogleAuth}>Authenticate by Google</a>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
