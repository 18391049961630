import { Button, Checkbox, Col, Row, Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getSkipLimit } from '../../common/helpers';
import { getEvents, selectEvents } from '../../redux/features/eventSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IEvent } from '../../types/IEvent';

const getColumns = (pathname: string): ColumnsType<IEvent> => ([
    {
        title: '#',
        dataIndex: '_id',
        key: '_id',
        render: (id) => <Link to={`${pathname}/${id}`} >{id}</Link>
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Game',
        dataIndex: 'game',
        key: 'game',
    },
    {
        title: 'Collections',
        dataIndex: 'collections',
        key: 'collections',
        render: (collections: IEvent['collections']) => collections.join(', '),
    },
    {
        title: 'Active',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (value: boolean) => <Checkbox checked={value} />
    },
]);

const Events: FC = () => {
    const { data: events, isFetching, count } = useAppSelector(selectEvents);
    const dispatch = useAppDispatch();

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const handleTableChange: TableProps<IEvent>['onChange'] = (newPagination, _filters, _sorter) => {
        setPagination(newPagination);
    }

    useEffect(() => {
        setPagination(currentPagination => ({ ...currentPagination, total: count }))
    }, [count]);

    // NOTE: somehow eslint(react-hooks/exhaustive-deps) rule is being triggered if we use direct property access from pagination e.g: pagination.current
    const { current, pageSize } = pagination;
    useEffect(() => {
        dispatch(getEvents(getSkipLimit(pageSize, current)));
    }, [dispatch, current, pageSize]);

    const { pathname } = useLocation();

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Link to={`${pathname}/create`}>
                        <Button type="primary">Create event</Button>
                    </Link>
                </Row>
            </Col>
            <Col span={24}>
                {/* <RequestFilters setFilters={handleFilters} /> */}
            </Col>
            <Table<IEvent>
                className="main-table"
                rowKey="_id"
                loading={isFetching}
                columns={getColumns(pathname)}
                dataSource={events}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </Row>
    )
};

export default Events;
