import { ICollectionDTO, ICollection } from '../types/ICollection';
import instance from './common/instance';
import { GetDataResponse, IQueryData } from './common/types';

export type GetCollectionsResponse = GetDataResponse<ICollection>;

class CollectionsService {
    private static prefix = 'collections';

    static getCollections({ skip, limit }: IQueryData) {
        return instance.get<GetCollectionsResponse>(`${this.prefix}?skip=${skip}&limit=${limit}`).then((res) => res.data);
    }

    static getCollection(collectionId: ICollection['_id']) {
        return instance.get<ICollection>(`${this.prefix}/${collectionId}`).then((res) => res.data);
    }

    static createCollection(data: ICollectionDTO) {
        return instance.post<ICollection>(`${this.prefix}`, data).then((res) => res.data);
    }

    static updateCollection(id: ICollection['_id'], data: ICollectionDTO) {
        return instance.put<ICollection>(`${this.prefix}/${id}`, data).then((res) => res.data);
    }
}

export default CollectionsService;