import { notification } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CollectionForm from '../../components/forms/CollectionForm';
import FormPageLayout from '../../components/Layouts/FormPageLayout';
import CollectionsService from '../../services/collection.service';
import { ICollectionDTO, ICollection } from '../../types/ICollection';

const UpdateCollection: FC = () => {
    const { collectionId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [collection, setCollection] = useState<ICollection | null>(null);

    useEffect(() => {
        let ignore = false;

        async function startFetching(id: ICollection['_id']) {
            try {
                setIsLoading(true);
                const collection = await CollectionsService.getCollection(id);
                if (!ignore) {
                    setCollection(collection);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }

        if (collectionId)
            startFetching(collectionId);

        return () => {
            ignore = true;
        };
    }, [collectionId]);

    const handleSubmit = async (data: ICollectionDTO) => {
        try {
            if (collectionId) {
                setIsLoading(true);
                await CollectionsService.updateCollection(collectionId, data);
                notification.success({
                    message: 'Collection updated!',
                });
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Collections">
            <CollectionForm collection={collection} isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default UpdateCollection;
