import { Button, DatePicker, Form, Input, Select, Spin } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { validateMessages } from "../../common/constants/validateMessages";
import { getSelectOptionFromDictionary } from "../../common/helpers";
import { getCollectionsDictionary, getGamesDictionary, selectDictionaries } from "../../redux/features/dictionariesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ICollection } from "../../types/ICollection";
import { IEventDTO, IEvent } from "../../types/IEvent";
import { IGame } from "../../types/IGame";

interface EventFormProps {
    event?: IEvent | null;
    isLoading: boolean;
    onSubmitValues: ((data: IEventDTO) => void);
}

interface IEventFormFields extends Omit<IEventDTO, 'startDate'> {
    startDate: moment.Moment;
}

const filterCollectionByGameId = (gameId?: IGame['_id']) => (collection: ICollection) => !!gameId && collection.game === gameId;

const EventForm: FC<EventFormProps> = ({ isLoading, event, onSubmitValues }) => {
    const [form] = useForm<IEventFormFields>();
    
    const { games, collections } = useAppSelector(selectDictionaries);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getGamesDictionary());
        dispatch(getCollectionsDictionary());
    }, [dispatch]);

    useEffect(() => {
        if (event) {
            form.setFieldsValue({
                ...event,
                startDate: moment(event.startDate),
            });
        }
    }, [event, form]);

    const gameValue = Form.useWatch('game', form);

    const onFinish = (values: IEventFormFields) => {
        const finalEvent = {
            ...values,
            startDate: values.startDate.toISOString(),
        }
        onSubmitValues(finalEvent);
    }

    return (
        <Spin spinning={isLoading}>
            <Form<IEventFormFields>
                form={form}
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    isActive: false,
                }}
            >
                <Form.Item
                    label="Game"
                    name="game"
                    rules={[{ required: true }]}
                >
                    <Select options={games.map(getSelectOptionFromDictionary)} onChange={() => form.setFieldsValue({ collections: [] })} />
                </Form.Item>
                <Form.Item
                    label="Collections"
                    name="collections"
                    rules={[{ required: true }]}
                >
                    <Select options={collections.filter(filterCollectionByGameId(gameValue)).map(getSelectOptionFromDictionary)} mode="multiple" />
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Count Down Time (UTC)"
                    name="startDate"
                    rules={[{ required: true }]}
                >
                    <DatePicker 
                        showTime
                        format={date => date.utc().format('YYYY-MM-DD HH:mm:ss')}
                    />
                </Form.Item>
                <Form.Item
                    label="Whitelist Link"
                    name="whiteList"
                    rules={[{ required: true }]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Active"
                    name="isActive"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}

export default EventForm;