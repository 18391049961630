import { ICollection } from '../types/ICollection';
import { IGame } from '../types/IGame';
import instance from './common/instance';

class DictionariesService {
    private static prefix = 'dict';

    static getGames() {
        return instance.get<IGame[]>(`${this.prefix}/games`).then((res) => res.data);
    }

    static getCollections() {
        return instance.get<ICollection[]>(`${this.prefix}/collections`).then((res) => res.data);
    }
}

export default DictionariesService;