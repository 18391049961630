import { Card, Row } from "antd";
import React, { FC, ReactNode } from "react";
import BackButton from "../BackButton";

interface FormPageLayoutProps {
    children: ReactNode;
    backButtonLabel: ReactNode;
}

const FormPageLayout: FC<FormPageLayoutProps> = ({ children, backButtonLabel }) => {
    return (
        <>
            <Row>
                <BackButton>{backButtonLabel}</BackButton>
            </Row>
            <Row justify="center">
                <Card bordered={false} bodyStyle={{ minWidth: '800px'}}>
                    {children}
                </Card>
            </Row>
        </>
    );
}

export default FormPageLayout;