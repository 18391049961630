import { notification } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CollectionForm from '../../components/forms/CollectionForm';
import FormPageLayout from '../../components/Layouts/FormPageLayout';
import CollectionsService from '../../services/collection.service';
import { ICollectionDTO } from '../../types/ICollection';

const CreateCollection: FC = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (data: ICollectionDTO) => {
        try {
            setIsLoading(true);
            const collection = await CollectionsService.createCollection(data);
            notification.success({
                message: 'Collection created!',
            });
            if (collection._id) {
                navigate(`/collections/${collection._id}`);
            }
        } catch (e) {
            setIsLoading(false);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Collections">
            <CollectionForm isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateCollection;
