import { notification } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GameForm from '../../components/forms/GameForm';
import FormPageLayout from '../../components/Layouts/FormPageLayout';
import GamesService from '../../services/game.service';
import { IGameDTO, IGame } from '../../types/IGame';

const UpdateGame: FC = () => {
    const { gameId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [game, setGame] = useState<IGame | null>(null);

    useEffect(() => {
        let ignore = false;

        async function startFetching(id: IGame['_id']) {
            try {
                setIsLoading(true);
                const game = await GamesService.getGame(id);
                if (!ignore) {
                    setGame(game);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }

        if (gameId)
            startFetching(gameId);

        return () => {
            ignore = true;
        };
    }, [gameId]);

    const handleSubmit = async (data: IGameDTO) => {
        try {
            if (gameId) {
                setIsLoading(true);
                await GamesService.updateGame(gameId, data);
                notification.success({
                    message: 'Game updated!',
                });
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Games">
            <GameForm game={game} isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default UpdateGame;
