import { Card, Layout, Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import logo from '../../assets/images/logo.png';
import Header from './Header';
import React from 'react';
import { IRouterList } from '../../types/IRouterList';
import Routers from '../../common/routes/Routes';
import RouteList, { menuIcons } from '../../common/routes/RouterList';

const { Content, Footer, Sider } = Layout;

const menuItems: ItemType[] = RouteList.filter(route => !!route.icon).map((route: IRouterList) => ({
    key: route.code,
    icon: route.icon && menuIcons[route.icon],
    label: route.name,
} as ItemType));

const MainLayout: FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    const handleMenuSelect: MenuProps['onSelect'] = ({ key }) => {
        const route = RouteList.find((item: IRouterList) => item.code === key);
        if (route) {
            navigate(route.path);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="logo">
                    {/* <img src={logo} alt="logo" width={'40%'} /> */}
                </div>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={menuItems} onSelect={handleMenuSelect} />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} />
                <Content style={{ margin: '20px' }}>
                    <Card style={{ height: '100%' }}>
                        <Routers />
                    </Card>
                </Content>
                <Footer style={{ textAlign: 'center' }}>HUSPI ©2022</Footer>
            </Layout>
        </Layout>
    );
};

export default MainLayout;

