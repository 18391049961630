import { CaseReducer, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryData } from '../../services/common/types';
import EventsService, { GetEventsResponse } from '../../services/event.service';
import { IEvent } from '../../types/IEvent';
import { RootState } from '../store';

interface IEventsState {
    data: IEvent[];
    isFetching: boolean;
    count: number;
}

const initialState: IEventsState = {
    data: [],
    count: 0,
    isFetching: false,
}

export const getEvents = createAsyncThunk<GetEventsResponse, IQueryData>(
    'events/getEvents',
    async (data, _thunkAPI) => {
        const response = await EventsService.getEvents(data);
        return response;
    }
)

const setEventsData: CaseReducer<IEventsState, PayloadAction<GetEventsResponse>> = (state, action) => { 
    state.data = action.payload.data;
    state.count = action.payload.count, 
    state.isFetching = false 
};

export const eventsSlice = createSlice({
    initialState,
    name: 'events',
    reducers: {
        setEvents: setEventsData,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEvents.fulfilled, setEventsData)
            .addCase(getEvents.pending, (state) => { state.isFetching = true })
            .addCase(getEvents.rejected, (state) => { state.isFetching = false })
    }
});

export default eventsSlice.reducer;

export const {
    setEvents,
} = eventsSlice.actions;

export const eventsSliceName = eventsSlice.name;

export const selectEvents = (state: RootState) => state.events;
