import { CaseReducer, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICollection } from '../../types/ICollection';
import { RootState } from '../store';
import { IGame } from '../../types/IGame';
import DictionariesService from '../../services/dictionary.service';

interface IDictionariesState {
    games: IGame[];
    collections: ICollection[]
}

const initialState: IDictionariesState = {
    games: [],
    collections: [],
}

export const getCollectionsDictionary = createAsyncThunk<ICollection[], void>(
    'dictionaries/getCollections',
    async (_data, _thunkAPI) => {
        const response = await DictionariesService.getCollections();
        return response;
    }
)

export const getGamesDictionary = createAsyncThunk<IGame[], void>(
    'dictionaries/getGames',
    async (_data, _thunkAPI) => {
        const response = await DictionariesService.getGames();
        return response;
    }
)

const setCollectionsData: CaseReducer<IDictionariesState, PayloadAction<ICollection[]>> = (state, action) => { 
    state.collections = action.payload;
};

const setGamesData: CaseReducer<IDictionariesState, PayloadAction<IGame[]>> = (state, action) => { 
    state.games = action.payload;
};

export const dictionariesSlice = createSlice({
    initialState,
    name: 'dictionaries',
    reducers: {
        setCollections: setCollectionsData,
        setGames: setGamesData,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCollectionsDictionary.fulfilled, setCollectionsData)
            .addCase(getGamesDictionary.fulfilled, setGamesData);
    }
});

export default dictionariesSlice.reducer;

export const {
    setCollections,
} = dictionariesSlice.actions;

export const dictionariesSliceName = dictionariesSlice.name;

export const selectDictionaries = (state: RootState) => state.dictionaries;
export const selectGamesDictionary = (state: RootState) => state.dictionaries.games;
export const selectCollectionsDictionary = (state: RootState) => state.dictionaries.collections;
