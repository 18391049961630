import { notification } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EventForm from '../../components/forms/EventForm';
import FormPageLayout from '../../components/Layouts/FormPageLayout';
import EventsService from '../../services/event.service';
import { IEventDTO, IEvent } from '../../types/IEvent';

const UpdateEvent: FC = () => {
    const { eventId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [event, setEvent] = useState<IEvent | null>(null);

    useEffect(() => {
        let ignore = false;

        async function startFetching(id: IEvent['_id']) {
            try {
                setIsLoading(true);
                const event = await EventsService.getEvent(id);
                if (!ignore) {
                    setEvent(event);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }

        if (eventId)
            startFetching(eventId);

        return () => {
            ignore = true;
        };
    }, [eventId]);

    const handleSubmit = async (data: IEventDTO) => {
        try {
            if (eventId) {
                setIsLoading(true);
                await EventsService.updateEvent(eventId, data);
                notification.success({
                    message: 'Event updated!',
                });
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Events">
            <EventForm event={event} isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default UpdateEvent;
