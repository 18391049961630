import { CaseReducer, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryData } from '../../services/common/types';
import CollectionsService, { GetCollectionsResponse } from '../../services/collection.service';
import { ICollection } from '../../types/ICollection';
import { RootState } from '../store';

interface ICollectionsState {
    data: ICollection[];
    isFetching: boolean;
    count: number;
}

const initialState: ICollectionsState = {
    data: [],
    count: 0,
    isFetching: false,
}

export const getCollections = createAsyncThunk<GetCollectionsResponse, IQueryData>(
    'collections/getCollections',
    async (data, _thunkAPI) => {
        const response = await CollectionsService.getCollections(data);
        return response;
    }
)

const setCollectionsData: CaseReducer<ICollectionsState, PayloadAction<GetCollectionsResponse>> = (state, action) => { 
    state.data = action.payload.data;
    state.count = action.payload.count, 
    state.isFetching = false 
};

export const collectionsSlice = createSlice({
    initialState,
    name: 'collections',
    reducers: {
        setCollections: setCollectionsData,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCollections.fulfilled, setCollectionsData)
            .addCase(getCollections.pending, (state) => { state.isFetching = true })
            .addCase(getCollections.rejected, (state) => { state.isFetching = false })
    }
});

export default collectionsSlice.reducer;

export const {
    setCollections,
} = collectionsSlice.actions;

export const collectionsSliceName = collectionsSlice.name;

export const selectCollections = (state: RootState) => state.collections;
