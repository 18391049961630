import axios from 'axios';
import { clearAuthData } from '../../redux/features/authSlice';
import { AppStore } from '../../redux/store';

let store: AppStore;

export const injectStore = (_store: AppStore) => {
    store = _store
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    responseType: 'json'
});

instance.interceptors.request.use(async config => {
    const token = store?.getState().auth.token;
    if(token) {
        config.headers.authorization = `Bearer ${token}`;
    }

    config.withCredentials = true;
    
    return config;
}, error => {
    throw error;
});

instance.interceptors.response.use(value => {
    return value;
}, error => {
    if (!(error.response && error.response.data && (error.response.data.message || error.response.data.error.message))) {
        error.response = {
            data: {
                message: error.message
            }
        }
    }

    if(error.response.status === 401) {
        store?.dispatch(clearAuthData());
    }

    throw error;
});

export default instance;