import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './components/Layouts/MainLayout';
import LoginPage from './pages/LoginPage';

import './assets/styles/styles.css';
import './assets/styles/common.css';
import './assets/styles/pages/login.css';
import './assets/styles/antd.css';
import PrivateRoute from './common/routes/PrivateRoute';

const App: FC = () => (
    <div className="App">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/change-password" element={<ChangePassword />} /> */}
        <Route path="*" element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
          } />
      </Routes>
    </div>
);

export default App;
