import { notification } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GameForm from '../../components/forms/GameForm';
import FormPageLayout from '../../components/Layouts/FormPageLayout';
import GamesService from '../../services/game.service';
import { IGameDTO } from '../../types/IGame';

const CreateGame: FC = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (data: IGameDTO) => {
        try {
            setIsLoading(true);
            const game = await GamesService.createGame(data);
            notification.success({
                message: 'Game created!',
            });
            if (game._id) {
                navigate(`/games/${game._id}`);
            }
        } catch (e) {
            setIsLoading(false);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Games">
            <GameForm isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateGame;
